import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      ref: "dialog",
      scopedSlots: _vm._u(
        [
          _vm.hasActivator
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "review-changes__banner" },
                      [
                        _c(
                          "span",
                          { staticClass: "lock-icon" },
                          [
                            _c("icon", {
                              attrs: { "icon-name": "parent-child-icon-red" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "lock-reason" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("general.alerts.locked")
                                )
                              )
                            ),
                          ]),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t(
                                    "planning.promotionsViewer.notificationExist"
                                  )
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          _vm._g(
                            {
                              staticClass: "review-changes__button",
                              attrs: {
                                disabled: _vm.isDisabled,
                                depressed: "",
                              },
                              on: { click: _vm.openDialog },
                            },
                            on
                          ),
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$tkey("reviewChanges")
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.isDialogOpen,
        callback: function ($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "dialog-card", attrs: { tile: "" } },
        [
          _c(
            "header",
            { staticClass: "dialog-card__header" },
            [
              _c("h1", { staticClass: "dialog-card__title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(_vm.$tkey("headings.general"))
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                VBtn,
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("icon", {
                    attrs: {
                      "icon-name": "cancel",
                      small: "",
                      "icon-colour-type": "primary-path",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "review-changes__description" },
            [
              _vm.isDisabled ? _c("spinner-dynamic") : _vm._e(),
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(_vm.$tkey("descriptions.general"))
                  ) +
                  "\n        "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "review-changes__grids" },
            [
              _vm.hasMechanicChanges
                ? [
                    _c("h1", { staticClass: "review-changes__title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$tkey("gridHeadings.offerMechanic")
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("offer-mechanic-changes", {
                      attrs: {
                        child: _vm.offerMechanic,
                        parent: _vm.changeset.changeset.offer,
                        "select-all-parent": _vm.toggleAllParent,
                        "select-all-child": _vm.toggleAllChild,
                      },
                      on: { "select-offer-mechanic": _vm.selectOfferMechanic },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.hasClientSpecificFieldsChanges
                ? [
                    _c("h1", { staticClass: "review-changes__title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$tkey(
                                "gridHeadings.additionalClientSpecificFields"
                              )
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("client-specific-fields-changes", {
                      attrs: {
                        child: _vm.commercialFields,
                        parent:
                          _vm.changeset.changeset
                            .additionalClientSpecificFields,
                        "select-all-parent": _vm.toggleAllParent,
                        "select-all-child": _vm.toggleAllChild,
                      },
                      on: {
                        "select-client-specific-fields":
                          _vm.selectClientSpecificFields,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.hasProductChanges
                ? [
                    _c("h1", { staticClass: "review-changes__title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$tkey("gridHeadings.products")
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("product-changes", {
                      attrs: {
                        child: _vm.selectedPromotion.products,
                        parent: _vm.changeset.changeset.products.products,
                        "select-all-parent": _vm.toggleAllParent,
                        "select-all-child": _vm.toggleAllChild,
                      },
                      on: { "select-product": _vm.selectProduct },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.hasFundingChanges
                ? [
                    _c("h1", { staticClass: "review-changes__title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$tkey("gridHeadings.funding")
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("funding-changes", {
                      attrs: {
                        "child-products": _vm.selectedPromotion.products,
                        "parent-changeset": _vm.changeset.changeset.funding.va,
                        "select-all-parent": _vm.toggleAllParent,
                        "select-all-child": _vm.toggleAllChild,
                      },
                      on: { "select-funding": _vm.selectFunding },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("footer", { staticClass: "dialog-card__footer" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      depressed: "",
                      outlined: "",
                      "data-id-e2e": "btnCancelId",
                    },
                    on: { click: _vm.closeDialog },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("toSentenceCase")(_vm.$t("actions.cancel"))
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  VBtn,
                  {
                    staticClass: "review-changes-btn--child",
                    attrs: { depressed: "", outlined: "" },
                    on: { click: _vm.selectAllChild },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("planning.parentChanges.selectAllChild")
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  VBtn,
                  {
                    staticClass: "review-changes-btn--parent",
                    attrs: { depressed: "", outlined: "" },
                    on: { click: _vm.selectAllParent },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("planning.parentChanges.selectAllParent")
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  VTooltip,
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on: criteria, attrs }) {
                          return [
                            _c(
                              "span",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "tooltip-wrapper" },
                                  "span",
                                  attrs,
                                  false
                                ),
                                criteria
                              ),
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticClass: "review-changes-btn--apply",
                                    attrs: {
                                      depressed: "",
                                      outlined: "",
                                      disabled:
                                        _vm.isDisabled ||
                                        _vm.confirmBtnDisabled,
                                      "data-id-e2e": "btnSubmitId",
                                    },
                                    on: { click: _vm.applyChanges },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t(
                                              "planning.parentChanges.apply"
                                            )
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.disabledReason ||
                              _vm.$t("planning.parentChanges.tooltips.apply")
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isDisabled
            ? _c(VOverlay, {
                staticClass: "review-changes__overlay",
                attrs: { absolute: true, value: true, opacity: 0.3 },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }