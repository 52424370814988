import Vue from 'vue';
import axios from 'axios';
import {
    merge,
    find,
    reduce,
    filter,
    findIndex,
    uniq,
    flatten,
    map,
    includes,
    get,
    isObject,
    unionBy,
    groupBy,
    orderBy,
    some,
    isEmpty,
    isNil,
    difference,
} from 'lodash';

import promoResourceSectionsEnum from '@enums/promo-resource-sections';
import promoResourcesEnum from '@enums/promo-resources';
import categoryAllocationsTypes from '@enums/category-allocations-types';
import to from 'await-to-js';
import { workflowAlertGrouping } from '@enums/feature-flags';
import namespaces from '@enums/namespaces';
import downloadJSONData from '@/js/utils/download-json';
import storeMixin from '@/js/store/mixins/vuex-store';
import i18n from '@/js/vue-i18n';
import downloadExcelData from '@/js/utils/download-excel';

const getInitialState = () => ({
    subCampaigns: [],
    childDependencies: null,
    selectedSubCampaignId: null,
    selectedSubCampaignParentId: null,
    selectedResourceDefinitionKey: null,
    selectedResourceDefinitionPageNumber: null,
    highlightedCategory: null,
    areaForCategoryAllocationSelected: false,
    selectedAreaForPromotionAllocation: null,
    normalizedSubCampaignsByCampaignId: {},
    activeDocumentSection: 0,
    activeAlerts: [],
    updatedResourceDefinitions: null,
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 * Inherits from the store form mixin which takes care of creating a staging area for creations / updates
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getSubCampaignById
     * - getFilteredSubCampaigns
     * - getFilteredSubCampaignsFromResourceList
     */
    getters: {
        selectedSubCampaignId: state => {
            return state.selectedSubCampaignId;
        },

        getSubCampaignChildOptions: (state, getters, rootState) => ({ subCampaignId }) => {
            // Filter the sub-campaigns on the following criteria:
            // 1. The sub-campaign hasn't already been assigned as a child.
            // 2. In the situation where we are editing an existing sub-campaign,
            //    ensure the selected sub-campaign is not included in the result set.
            // 3. There is an overlap between the parent subCampaign categories and the
            //    child subCampaign categories.
            // 4. Sub-campaign start date is in the future.
            const today = Vue.moment()
                .startOf('day')
                .utc();

            const subCampaignCategories =
                get(state.stagingArea[subCampaignId], 'categories') ||
                get(state.stagingArea.default, 'categories') ||
                [];
            const subCampaignChildOptions = filter(
                rootState.subCampaigns.subCampaigns,
                subCampaign => {
                    const hasNoExistingParent =
                        !subCampaign.parentId || subCampaignId === subCampaign.parentId;
                    const isNotSelectedSubCampaign = subCampaign._id !== subCampaignId;
                    const overlappingCategories = get(subCampaign, ['categories'], []).some(
                        category => subCampaignCategories.includes(category)
                    );

                    const subCampaignUTCStartDate = Vue.moment(subCampaign.startDate).utc();
                    const isStartDateInFuture = subCampaignUTCStartDate.isSameOrAfter(today);

                    return (
                        hasNoExistingParent &&
                        isNotSelectedSubCampaign &&
                        overlappingCategories &&
                        isStartDateInFuture
                    );
                }
            );

            return subCampaignChildOptions.map(subCampaign => {
                return {
                    id: subCampaign._id,
                    name: subCampaign.name,
                };
            });
        },
        getRateCardsOptions: (state, getters, rootState, rootGetters) => ({ subCampaignId }) => {
            // as we fetch all fitting to subCampaign rateCards, we can use them directly
            const options = rootState.rateCards.rateCards.map(rateCard => {
                return {
                    id: rateCard._id,
                    name: rateCard.description,
                    disabled: false,
                };
            });

            if (!subCampaignId) {
                return options;
            }
            const scenarios = rootGetters['scenarios/getScenariosBySubCampaignId'](subCampaignId);

            const promotions = rootGetters['promotions/byScenarioIds'](
                scenarios.map(scenario => scenario._id)
            );

            const rateCardIds = uniq(
                flatten(promotions.map(promotion => promotion.rateCards)).map(
                    rateCard => rateCard._id
                )
            );
            options.forEach(option => {
                option.disabled = rateCardIds.includes(option.id);
            });

            return options;
        },

        getSubCampaignsByCampaignId: state => campaignId =>
            state.normalizedSubCampaignsByCampaignId[campaignId] || [],

        getSubCampaignNamesByIds: state => ids =>
            state.subCampaigns.filter(s => ids.includes(s._id)).map(s => s.name),

        getFilteredSubCampaignsByCampaignId: (state, getters) => campaignId => {
            const subCampaignsByCampaignIdList =
                state.normalizedSubCampaignsByCampaignId[campaignId] || [];
            return getters.getFilteredSubCampaignsFromResourceList(subCampaignsByCampaignIdList);
        },
        hasNoPromotions: (state, getters, rootState, rootGetters) => ({ subCampaignId }) =>
            rootGetters['scenarios/hasNoPromotions']({ subCampaignId }),
        selectedSubCampaign: (state, getters) => {
            return find(getters.getSubCampaignsByCampaignId(state.selectedSubCampaignParentId), {
                _id: state.selectedSubCampaignId,
            });
        },
        getSelectedSubCampaignStoreGroupOptions: (state, getters, rootState, rootGetters) => ({
            subCampaignId,
        }) => {
            const { storeGroups = [] } = get(
                state.stagingArea,
                subCampaignId || namespaces.default
            );

            return storeGroups.map(storeGroup => ({
                reference: storeGroup,
                disabled: isNil(rootGetters['context/userStoreGroupsMap'][storeGroup.key]) || false,
            }));
        },
        getSelectedSubCampaignResourceOptions: state => ({ subCampaignId }) => {
            const { resources = [] } = get(state.stagingArea, subCampaignId || namespaces.default);

            return resources.map(resource => ({
                key: resource.type,
                instances: resource.instances,
                clientKey: resource.clientKey,
                disabled: false,
            }));
        },
        selectedResourceDefinition: state =>
            find(state.stagingArea[state.selectedSubCampaignId].resourceDefinitions, {
                key: state.selectedResourceDefinitionKey,
            }),
        selectedResourceDefinitionPage: (state, getters) => {
            if (getters.selectedResourceDefinition) {
                const pageIndex = getters.selectedResourceDefinition.pages.findIndex(
                    page => page.pageNumber === state.selectedResourceDefinitionPageNumber
                );
                return getters.selectedResourceDefinition.pages[pageIndex];
            }
        },
        isTemplatesSection: (state, getters) => {
            return (
                state.activeDocumentSection === promoResourceSectionsEnum.templates &&
                getters.isPagesResource
            );
        },
        isCategoriesSection: state => {
            return state.activeDocumentSection === promoResourceSectionsEnum.categories;
        },
        isPromotionsSection: (state, getters) => {
            return (
                state.activeDocumentSection === promoResourceSectionsEnum.promotions ||
                getters.isSlotsResource
            );
        },
        isPagesResource: (state, getters) => {
            if (getters.selectedResourceDefinition) {
                return [promoResourcesEnum.leaflet].includes(
                    getters.selectedResourceDefinition.resourceKey
                );
            }
            return false;
        },
        isSlotsResource: (state, getters) => {
            if (getters.selectedResourceDefinition) {
                return [promoResourcesEnum.tv, promoResourcesEnum.radio].includes(
                    getters.selectedResourceDefinition.resourceKey
                );
            }
            return false;
        },
        getCheckboxListOptions: (state, getters, rootState, rootGetters) => ({
            campaignId,
            subCampaignId,
            promotionAttributeName,
            promotionAttributeKey,
            resource,
            getOptionsFunction,
            campaignAttributeName = promotionAttributeName,
            campaignAttributeKey = promotionAttributeKey,
            getUserAccessOptionsMap,
        }) => {
            const selectedCampaign = rootGetters['campaigns/getCampaignById']({
                _id: campaignId,
                usePluralResourceName: true,
            });
            if (selectedCampaign || !campaignId) {
                // Retrieve all scenarios associated with the selected sub-campaign.
                const scenarios = rootGetters['scenarios/getScenariosBySubCampaignId'](
                    subCampaignId
                );

                // Retrieve all of the promotions associated with all of the scenarios.
                const promotions = rootGetters['promotions/byScenarioIds'](
                    scenarios.map(scenario => scenario._id)
                );

                const getKeys = (value, key) => (isObject(value) ? value[key] : value);

                let uniqueAttributeValues;

                // Categories have to be handled differently because we only need to look at the scenario categories.
                // Any scenario with a promotion in it should have its (the scenario's) categories disabled so that they can't be removed.
                // Because the promotion categories are a subset of the scenario categories, the promotions themselves don't need checking.
                if (campaignAttributeName === 'categories') {
                    // Get the ID of all scenarios with at least one promotion.
                    const scenarioIdsWithPromotions = uniq(
                        map(promotions, promotion => promotion.scenarioId)
                    );

                    // Add those scenario's categories to the uniqueAttributeValues so that those
                    // categories can't be removed. This stops those scenarios from being deleted
                    // (which means users don't lose all the promotions they've planned).
                    const scenarioCategoriesToExclude = scenarios
                        .filter(scenario => scenarioIdsWithPromotions.includes(scenario._id))
                        .map(scenario => scenario.categories);

                    // Flatten the array per scenario to a single array and remove any duplicate categories.
                    uniqueAttributeValues = uniq(flatten(scenarioCategoriesToExclude));
                } else {
                    // Get the values present on the promotion to use for filtering what's available on the sub-campaign.
                    // Example: with an attributeName of 'customerGroups', this returns something like the below.
                    // There is an inner array per promotion with one or more objects for each of the customer groups applicable:
                    // [[{ key: "allCustomers", description: "all customers" }], [{ key: "allCustomers", description: "all customers" }]]
                    // For 'categories', it would return something similar, except categories is an array of integers, rather than objects:
                    // [[7], [2, 7]]
                    const promotionAttributes = map(promotions, promotionAttributeName);

                    // Flatten everything to a single array. i.e.:
                    // [{ key: "allCustomers", description: "all customers" }, { key: "allCustomers", description: "all customers" }]
                    // [7, 2, 7]
                    const flattenedPromotionAttributes = flatten(promotionAttributes);

                    // Extract the values to be used for filtering the resource options. i.e.:
                    // ["allCustomers", "allCustomers"] (assuming an 'attributeKey' of 'key')
                    // [7, 2, 7] (categories is not an object so no values need extracting)
                    const attributeValues = map(flattenedPromotionAttributes, attribute =>
                        getKeys(attribute, promotionAttributeKey)
                    );

                    // De-dupe the extracted values. i.e.:
                    // ["allCustomers"]
                    // [7, 2]
                    uniqueAttributeValues = uniq(attributeValues);
                }

                // Retrieve the full list of options for the given attribute
                let resourceOptions = rootGetters[`${resource}/${getOptionsFunction}`];

                // If applicable, filter those based upon what's present on the campaign.
                if (selectedCampaign) {
                    resourceOptions = filter(resourceOptions, resourceOption => {
                        // get uniq list of resource values or
                        // keys, if resource is stored as list of objects
                        const campaignResourceOptionsKeys = map(
                            selectedCampaign[campaignAttributeName],
                            attribute => getKeys(attribute, campaignAttributeKey)
                        );

                        return includes(
                            campaignResourceOptionsKeys,
                            resourceOption[campaignAttributeKey]
                        );
                    });
                }

                // When any unit category is disabled we disable unit
                if (campaignAttributeName === 'units') {
                    const categoriesOptions = getters.getCheckboxListOptions({
                        campaignId,
                        subCampaignId,
                        resource,
                        getOptionsFunction: 'userCategories',
                        campaignAttributeName: 'categories',
                        campaignAttributeKey,
                    });

                    return map(resourceOptions, resourceOption => {
                        return {
                            reference: resourceOption,
                            disabled: some(
                                categoriesOptions,
                                option =>
                                    option.disabled &&
                                    option.reference.parentId === resourceOption.levelEntryKey
                            ),
                        };
                    });
                }

                // Return the list of available options with options disabled
                // based upon what's present on the promotions.
                const resultOption = map(resourceOptions, resourceOption => {
                    const optionKey = resourceOption[campaignAttributeKey];
                    return {
                        reference: resourceOption,
                        disabled:
                            // check if user has no access to sub-campaign resource option
                            (getUserAccessOptionsMap &&
                                isNil(
                                    rootGetters[`context/${getUserAccessOptionsMap}`][optionKey]
                                )) ||
                            // check if sub-campaign resource option is selected in child scenarios
                            includes(uniqueAttributeValues, optionKey),
                    };
                });
                if (promotionAttributeName === 'storeGroups') {
                    const subCampaign = state.stagingArea[subCampaignId || 'default'];
                    const rateCardIds = subCampaign.rateCards || [];
                    const rateCards = rootGetters['rateCards/getRateCardsByIds'](rateCardIds);
                    const subCampaignStoreGroups = (subCampaign.storeGroups || []).map(
                        sg => sg.key
                    );
                    const blockedStoreGroups = uniq(
                        flatten(rateCards.map(rateCard => rateCard.storeGroups))
                            .map(storeGroup => storeGroup.key)
                            .filter(bsg => subCampaignStoreGroups.includes(bsg))
                    );

                    return resultOption.map(option => {
                        return {
                            ...option,
                            disabled:
                                blockedStoreGroups.includes(option.reference.key) ||
                                option.disabled,
                        };
                    });
                }
                return resultOption;
            }
        },
        getPromotionCategoriesBySubCampaignId: (state, getters, rootState, rootGetters) => ({
            subCampaignId,
        }) => {
            const promotions = rootGetters['promotions/allPromotionsBySubCampaignId'](
                subCampaignId
            );

            const allCategories = flatten(
                map(promotions, promotion => [
                    ...promotion.categories,
                    ...(promotion.userSelectedCategories || []),
                ])
            );

            return uniq(
                map(allCategories, value => (isObject(value) ? value.levelEntryKey : value))
            );
        },
        getPagePerformance: (state, getters, rootState, rootGetters) => page => {
            const initialPagePerformance = {
                assignedAreasCount: 0,
                incrementalMargin: 0,
                incrementalSales: 0,
                salesEfficiency: 0,
                marginEfficiency: 0,
                salesDiscountExcTax: 0,
            };
            const performance = page.assignment.reduce((acc, assignment) => {
                if (assignment.categoryKey === categoryAllocationsTypes.nonAllocated) {
                    acc.assignedAreasCount += 1;
                } else if (assignment.promotionId) {
                    const promotion = rootGetters['promotions/getPromotionById'](
                        assignment.promotionId
                    );
                    if (promotion) {
                        acc.assignedAreasCount += 1;
                        // incrementalMargin = incrementalMarginBeforeFunding + Funding
                        acc.incrementalMargin += +get(
                            promotion,
                            'forecastingAggregations.promotion.incrementalMargin',
                            0
                        );
                        acc.incrementalSales += +get(
                            promotion,
                            'forecastingAggregations.promotion.incrementalSalesExcTax',
                            0
                        );
                        acc.salesDiscountExcTax += +get(
                            promotion,
                            'forecastingAggregations.promotion.salesDiscountExcTax',
                            0
                        );
                    }
                }
                return acc;
            }, initialPagePerformance);

            let templateCapacity = 0;
            const templateLayoutsByKey = rootGetters['clientConfig/templateLayoutsByKey'];
            if (page.slots) {
                templateCapacity = page.slots.length;
            } else if (templateLayoutsByKey && templateLayoutsByKey[page.layoutTemplate]) {
                templateCapacity = templateLayoutsByKey[page.layoutTemplate].areaNames.length;
            }
            performance.assignedAreasPerformance = templateCapacity
                ? performance.assignedAreasCount / templateCapacity
                : 0;
            performance.pageAllSpacesCount = templateCapacity;
            // Sales Efficiency: (-1)  x SUM(incrementalSalesExcTax) / SUM(salesDiscountExcTax)
            performance.salesEfficiency =
                (-1 * performance.incrementalSales) / performance.salesDiscountExcTax || 0;

            // pageMarginEfficiency: (-1) x SUM(incrementalMarginBeforeFunding + Funding) / SUM(salesDiscountExcTax)
            // incrementalMarginBeforeFunding + Funding = incrementalMargin
            performance.marginEfficiency =
                (-1 * performance.incrementalMargin) / performance.salesDiscountExcTax || 0;
            return performance;
        },
        selectedDocumentPerformance: (state, getters) => {
            const document = getters.selectedResourceDefinition;
            const initialDocumentPerformance = {
                completeSpaces: 0,
                allSpaces: 0,
                aggregatedSales: 0,
                aggregatedMargin: 0,
                aggregatedSalesEfficiency: 0,
                aggregatedMarginEfficiency: 0,
                aggregatedSalesDiscountExcTax: 0,
                pages: {},
            };
            if (document) {
                const documentPerformance = document.pages.reduce((acc, page) => {
                    acc.completeSpaces += page.assignment.filter(
                        assignment =>
                            assignment.promotionId !== null ||
                            assignment.categoryKey === categoryAllocationsTypes.nonAllocated
                    ).length;
                    acc.pages[page.key] = getters.getPagePerformance(page);
                    acc.allSpaces += acc.pages[page.key].pageAllSpacesCount;
                    acc.aggregatedSales += acc.pages[page.key].incrementalSales;
                    acc.aggregatedMargin += acc.pages[page.key].incrementalMargin;
                    acc.aggregatedSalesDiscountExcTax += acc.pages[page.key].salesDiscountExcTax;
                    return acc;
                }, initialDocumentPerformance);

                // Sales Efficiency: (-1)  x SUM(incrementalSalesExcTax) / SUM(salesDiscountExcTax)
                // NaN got if aggregatedSalesDiscountExcTax = 0, for this case 0 set as default
                documentPerformance.aggregatedSalesEfficiency =
                    (-1 * documentPerformance.aggregatedSales) /
                        documentPerformance.aggregatedSalesDiscountExcTax || 0;

                // pageMarginEfficiency: (-1) x SUM(incrementalMarginBeforeFunding + Funding) / SUM(salesDiscountExcTax)
                // incrementalMarginBeforeFunding + Funding = incrementalMargin
                // NaN got if aggregatedSalesDiscountExcTax = 0, for this case 0 set as default
                documentPerformance.aggregatedMarginEfficiency =
                    (-1 * documentPerformance.aggregatedMargin) /
                        documentPerformance.aggregatedSalesDiscountExcTax || 0;

                return documentPerformance;
            }
            return initialDocumentPerformance;
        },
        getResourceDefinitionByResourceKey: (state, getters) => ({ resourceKey }) => {
            return getters.selectedSubCampaign.resourceDefinitions.find(
                rd => rd.key === resourceKey
            );
        },
        getResourceDefinitionsForResourceType: state => resourceType => {
            if (!state.stagingArea[state.selectedSubCampaignId]) {
                return [];
            }
            const definitionsForCurrentResource = state.stagingArea[
                state.selectedSubCampaignId
            ].resourceDefinitions.filter(def => def.resourceKey === resourceType);
            return definitionsForCurrentResource.map(def => {
                return {
                    key: def.key,
                    title: i18n.translationExists(`preparation.templates.${def.templateName}`)
                        ? i18n.t(`preparation.templates.${def.templateName}`)
                        : def.templateName,
                    subtitle: def.storeGroups.map(sgKey => sgKey.description).join(', '),
                    subType: get(def, 'subType.key', ''),
                };
            });
        },
        entityWorkflowStateById: (state, getters) => ({ id, getChildEntities = true }) => {
            const { workflowState: subCampaignWorkflowState = [] } = getters.getSubCampaignById({
                _id: id,
                usePluralResourceName: true,
            });

            const aggregatedScenariosState = getChildEntities
                ? getters.getChildWorkflowStates({
                      namespace: 'scenarios',
                      getter: 'getScenariosBySubCampaignId',
                      id,
                  })
                : [];

            return [...subCampaignWorkflowState, ...aggregatedScenariosState];
        },
        promoResourceWorkflowStateById: (state, getters) => ({
            id,
            subCampaignId,
            getChildEntities = true,
        }) => {
            const subCampaign = getters.getSubCampaignById({
                _id: subCampaignId,
                usePluralResourceName: true,
            });

            // Retrieve the aggregated state of promotions assigned to this promo resource.
            const aggregatedPromotionsState = getChildEntities
                ? getters.getChildWorkflowStates({
                      namespace: 'promotions',
                      getter: 'getPromotionsAllocatedToPromoResource',
                      id,
                  })
                : [];

            const {
                workflowState: promoResourceWorkflowState,
            } = subCampaign.resourceDefinitions.find(rd => rd.key === id);

            return [...promoResourceWorkflowState, ...aggregatedPromotionsState];
        },

        isCover: state => state.selectedResourceDefinitionPageNumber === 1,
        getAlertsByPriority: state => {
            return groupBy(state.activeAlerts, 'priority');
        },
        alertsGroupedByConfiguredCategories: (state, getters, rootState) => {
            const alertGrouping = rootState.clientConfig.toggleLogic[workflowAlertGrouping];

            // Iterate over alert grouping map from the config.
            // This will contain an array of arrays. The outer array represents
            // each column which should be displayed. The inner arrays denote
            // the alert priorities which are displayed in that column
            return alertGrouping.map(priorities => {
                const alerts = [];
                priorities.forEach(priority => {
                    alerts.push(...getters.getAlertsByPriority[priority]);
                });

                return {
                    key: priorities,
                    alerts: orderBy(alerts, 'deadlineDateTime'),
                };
            });
        },

        hasAccessToAllSubCampaignCategories: (state, getters, rootState, rootGetters) => {
            const userCategoryKeys = rootGetters['context/userCategoryKeys'];
            const subCampaign =
                getters.selectedSubCampaign ||
                get(rootState, 'parkingLotFilters.selections.subCampaign', {});
            // need for cover case when we create promo in the parking lot and subCampaign doesn't exist
            const categories = get(subCampaign, 'categories', []);

            return isEmpty(difference(categories, userCategoryKeys));
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setSubCampaigns
     * - deleteSubCampaign
     * - updateSubCampaign
     * - addSubCampaign
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {
        setActiveAlerts(state, activeAlerts) {
            state.activeAlerts = activeAlerts;
        },
        setChildDependencies(state, dependencies) {
            state.childDependencies = dependencies;
        },
        setSelectedSubCampaignId(state, selectedSubCampaignId) {
            state.selectedSubCampaignId = selectedSubCampaignId;
        },
        setSelectedSubCampaignParentId(state, campaignId) {
            state.selectedSubCampaignParentId = campaignId;
        },
        setSelectedResourceDefinitionKey(state, selectedResourceDefinitionKey) {
            state.selectedResourceDefinitionKey = selectedResourceDefinitionKey;
        },
        setSelectedResourceDefinitionPageNumber(state, selectedResourceDefinitionPageNumber) {
            state.selectedResourceDefinitionPageNumber = selectedResourceDefinitionPageNumber;
        },
        setHighlightedCategory(state, highlightedCategory) {
            state.highlightedCategory = highlightedCategory;
        },
        setAreaForCategoryAllocationSelected(state, areaForCategoryAllocationSelected) {
            state.areaForCategoryAllocationSelected = areaForCategoryAllocationSelected;
        },
        setSelectedAreaForPromotionAllocation(state, selectedAreaForPromotionAllocation) {
            state.selectedAreaForPromotionAllocation = selectedAreaForPromotionAllocation;
        },
        setSubCampaigns(state, subCampaigns) {
            state.subCampaigns = subCampaigns;
            state.normalizedSubCampaignsByCampaignId = reduce(
                subCampaigns,
                (acc, subCampaign) => {
                    const { campaignId } = subCampaign;
                    (acc[campaignId] || (acc[campaignId] = [])).push(subCampaign);
                    return acc;
                },
                {}
            );
        },
        deleteSubCampaign(state, id) {
            let campaignId;
            state.subCampaigns = filter(state.subCampaigns, item => {
                const isDeleted = item._id === id;
                if (isDeleted) {
                    campaignId = item.campaignId;
                }
                return !isDeleted;
            });

            state.normalizedSubCampaignsByCampaignId[campaignId] = filter(
                state.normalizedSubCampaignsByCampaignId[campaignId],
                item => item._id !== id
            );

            if (!state.normalizedSubCampaignsByCampaignId[campaignId].length) {
                delete state.normalizedSubCampaignsByCampaignId[campaignId];
            }
        },

        updateSubCampaign(state, { id, updates }) {
            let targetIx = findIndex(state.subCampaigns, item => item._id === id);
            const subCampaign = state.subCampaigns[targetIx];
            const updatedSubCampaign = { ...subCampaign, ...updates };
            Vue.set(state.subCampaigns, targetIx, updatedSubCampaign);

            targetIx = findIndex(
                state.normalizedSubCampaignsByCampaignId[subCampaign.campaignId],
                item => item._id === id
            );
            Vue.set(
                state.normalizedSubCampaignsByCampaignId[subCampaign.campaignId],
                targetIx,
                updatedSubCampaign
            );
        },

        addSubCampaign(state, subCampaign) {
            state.subCampaigns.push(subCampaign);

            if (!state.normalizedSubCampaignsByCampaignId[subCampaign.campaignId]) {
                Vue.set(state.normalizedSubCampaignsByCampaignId, subCampaign.campaignId, []);
            }

            state.normalizedSubCampaignsByCampaignId[subCampaign.campaignId].push(subCampaign);
        },

        setActiveDocumentSection(state, activeDocumentSection) {
            state.activeDocumentSection = activeDocumentSection;
        },

        updatePagesNotesCopy(state, { parentId: pageKey, updates: updatedSuCampaign }) {
            // need to update notes in pages from stagingArea resourceDefinitions,
            // as it's a copy of original object
            // and selectedResourceDefinition is in edit mode, while updating notes
            const selectedResourceDefinition = find(
                state.stagingArea[state.selectedSubCampaignId].resourceDefinitions,
                {
                    key: state.selectedResourceDefinitionKey,
                }
            );
            const page = find(selectedResourceDefinition.pages, { key: pageKey });

            const updatedSelectedResourceDefinition = find(updatedSuCampaign.resourceDefinitions, {
                key: state.selectedResourceDefinitionKey,
            });
            const updatedPage = find(updatedSelectedResourceDefinition.pages, { key: pageKey });
            Vue.set(page, 'notes', updatedPage.notes);
        },
        setSubCampaignIsPrivateAttribute(state, { id, isPrivate }) {
            if (state.stagingArea[id]) {
                state.stagingArea[id].isPrivate = isPrivate;
            }
        },

        /**
         * Update the workflow state in vuex.
         *
         * @param {string} RORO.promoResourceToUpdate - The entity being updated.
         * @param {Array} RORO.states - The array of workflow states to be updated.
         */
        updateWorkflowState(state, { promoResourceToUpdate, states }) {
            if (!promoResourceToUpdate.workflowState) {
                Vue.set(promoResourceToUpdate, 'workflowState', []);
            }

            states.forEach(s => {
                const workflowState = find(promoResourceToUpdate.workflowState, {
                    entity: s.entityToUpdate,
                    state: s.stateToUpdate,
                });

                if (workflowState) {
                    workflowState.value = s.value;
                    workflowState.actionDateTime = s.actionDateTime;
                } else {
                    promoResourceToUpdate.workflowState.push({
                        entity: s.entityToUpdate,
                        state: s.stateToUpdate,
                        value: s.value,
                        actionDateTime: s.actionDateTime,
                    });
                }
            });

            if (state.selectedSubCampaignId && state.selectedResourceDefinitionKey) {
                const selectedResourceDefinition = find(
                    state.stagingArea[state.selectedSubCampaignId].resourceDefinitions,
                    { key: state.selectedResourceDefinitionKey }
                );

                if (selectedResourceDefinition) {
                    selectedResourceDefinition.workflowState = promoResourceToUpdate.workflowState;
                }
            }
        },

        setUpdatedResourceDefinitions(state, updatedResourceDefinitions) {
            const subCampaignId = state.selectedSubCampaignId;
            state.updatedResourceDefinitions = { subCampaignId, updatedResourceDefinitions };
        },
    },

    /**
     * Default actions available:
     * - fetchSubCampaigns
     * - deleteSubCampaign
     * - updateSubCampaign
     * - createSubCampaign
     * - submitForm
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {
        async deleteSubCampaignById({ getters, dispatch }, { id }) {
            const subCampaignToDelete = getters.getSubCampaignById({
                _id: id,
                usePluralResourceName: true,
            });

            // refresh parent and child sub-campaigns if any
            const subCampaignIds = get(subCampaignToDelete, 'children', []);
            const { parentId } = subCampaignToDelete;
            if (parentId) {
                subCampaignIds.push(parentId);
            }
            await dispatch('deleteSubCampaign', { id });
            await dispatch('refreshSubCampaigns', { subCampaignIds });
        },

        async fetchChildDependencies({ commit }, { id, includeGhosts = false }) {
            let url = `/api/hierarchy/child-dependencies/subCampaigns/${id}`;
            // when we delete sub-campaign we also need to pick up ghost promotions
            if (includeGhosts) {
                url += `/includeGhosts`;
            }

            const { data: dependencies } = await axios.get(url);
            commit('setChildDependencies', dependencies);
        },
        async fetchActiveAlerts({ commit }) {
            const { data: activeAlerts } = await axios.get(
                '/api/sub-campaigns/alerts?activeOnly=true'
            );
            commit('setActiveAlerts', activeAlerts);
        },
        async fetchAndUpdateSubCampaigns({ state, commit, dispatch }, { fetchParams = {} }) {
            const [error, subCampaigns] = await to(
                axios.get('/api/sub-campaigns', { fetchParams })
            );
            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.subCampaigns', 1),
                    }),
                });
            } else {
                const updatedSubCampaigns = unionBy(subCampaigns.data, state.subCampaigns, '_id');
                commit('setSubCampaigns', updatedSubCampaigns);
            }
        },
        fetchSubCampaignWithDependencies(
            { dispatch, rootState },
            { subCampaign, scenario, patchState = true, promotionFields }
        ) {
            const getScenarioDependencies = async () => {
                await dispatch('scenarios/fetchScenariosForSubCampaign', subCampaign, {
                    root: true,
                });
                await dispatch(
                    'promotions/fetchPromotionsForSubCampaign',
                    { subCampaign, scenario, promotionFields },
                    {
                        root: true,
                    }
                );

                const rateCardsAtSubCampaignLevel =
                    rootState.clientConfig.toggleLogic.rateCardsAtSubCampaignLevel;

                if (rateCardsAtSubCampaignLevel) {
                    dispatch(
                        'rateCards/fetchRateCardsByStoreGroupsAndDates',
                        {
                            subCampaignDetails: {
                                storeGroups: subCampaign.storeGroups,
                                startDate: subCampaign.startDate,
                                endDate: subCampaign.endDate,
                            },
                        },
                        { root: true }
                    );
                }

                if (subCampaign.parentId) {
                    const parentPromotionIds = rootState.promotions.promotions.reduce(
                        (acc, promo) => {
                            if (promo.parentPromotionId) {
                                acc.push(promo.parentPromotionId);
                            }
                            return acc;
                        },
                        []
                    );
                    const parentPromotions = await dispatch(
                        'promotions/fetchPromotionsState',
                        {
                            params: {
                                where: {
                                    _id: { $in: parentPromotionIds },
                                },
                            },
                        },
                        { root: true }
                    );
                    dispatch('promotions/setParentPromotions', parentPromotions, { root: true });
                }
            };
            return Promise.all([
                dispatch(
                    'campaigns/fetchCampaigns',
                    {
                        params: {
                            where: {
                                _id: subCampaign.campaignId,
                            },
                        },
                        patchState,
                    },
                    { root: true }
                ),
                dispatch('fetchSubCampaigns', {
                    params: {
                        where: {
                            campaignId: subCampaign.campaignId,
                        },
                    },
                    patchState,
                }),
                getScenarioDependencies(),
            ]);
        },

        async refreshSubCampaigns({ dispatch }, { subCampaignIds }) {
            return dispatch('fetchSubCampaigns', {
                params: {
                    where: {
                        _id: { $in: subCampaignIds },
                    },
                },
                patchState: true,
                patchOptions: {
                    fieldToMatchOn: '_id',
                    isMatchFunction: subCampaign => includes(subCampaignIds, subCampaign._id),
                },
            });
        },

        setSelectedSubCampaignId({ commit }, subCampaign) {
            const selectedSubCampaignId = subCampaign ? subCampaign._id : null;
            const campaignId = subCampaign ? subCampaign.campaignId : null;

            commit('setSelectedSubCampaignId', selectedSubCampaignId);
            commit('setSelectedSubCampaignParentId', campaignId);
        },
        clearSelectedSubCampaignId({ commit }) {
            commit('setSelectedSubCampaignId', null);
            commit('setSelectedSubCampaignParentId', null);
        },
        async updatePromoResourceWorkflowState(
            { dispatch, getters, commit },
            { entityIds, subCampaignId, states, resource }
        ) {
            const payload = {
                resource,
                entityIds,
                owningEntityId: subCampaignId,
                states,
            };

            // Update the actionDateTime attribute of all the updated states.
            const updatedActionDateTime = Vue.moment();
            states.forEach(state => {
                state.actionDateTime = updatedActionDateTime;
            });

            // Send api request to update state
            const [error, response] = await to(axios.post(`/api/workflow/workflowState`, payload));
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`workflow.updateState.notifications.updateSuccess`),
                errorMessage: i18n.t(`workflow.updateState.notifications.updateError`),
            });
            if (error) return { error };

            // Update state in vuex
            const subCampaignToUpdate = getters.getSubCampaignById({
                _id: subCampaignId,
                usePluralResourceName: true,
            });

            entityIds.forEach(entityId => {
                const promoResourceToUpdate = subCampaignToUpdate.resourceDefinitions.find(
                    resourceDefinition => resourceDefinition.key === entityId
                );
                commit('updateWorkflowState', {
                    promoResourceToUpdate,
                    states,
                });
            });
        },
        async downloadJsonResourceDefinition({ state, dispatch }, { params }) {
            const subCampaignId = state.selectedSubCampaignId;
            const key = state.selectedResourceDefinitionKey;
            const url = `/api/sub-campaigns/${subCampaignId}/resource-definitions/${key}`;

            const [error, response] = await to(axios.get(url, { params }));
            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.subCampaigns', 1),
                    }),
                });
            }

            downloadJSONData(response.data, i18n.t('preparation.promoResources.exportFileName'));
        },

        async downloadExcelResourceDefinition({ state, dispatch, getters }, { params }) {
            const subCampaignId = state.selectedSubCampaignId;
            const { campaignId } = getters.selectedSubCampaign;
            const key = state.selectedResourceDefinitionKey;

            params.campaignId = campaignId;
            const url = `/api/sub-campaigns/${subCampaignId}/resource-definitions/${key}/excel`;

            const [error, response] = await to(
                axios({ url, method: 'get', params, responseType: 'blob' })
            );
            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.subCampaigns', 1),
                    }),
                });
            }
            downloadExcelData(response.data, i18n.t('preparation.promoResources.exportFileName'));
        },

        async toggleSelectedSubCampaign({ getters, dispatch, commit }, subCampaign) {
            dispatch('scenarios/setSelectedScenarioId', null, { root: true });
            commit('setSelectedResourceDefinitionKey', null);
            commit('setSelectedResourceDefinitionPageNumber', null);

            const selectedSubCampaign = getters.selectedSubCampaign;

            if (selectedSubCampaign && selectedSubCampaign._id === subCampaign._id) {
                dispatch('setSelectedSubCampaignId', null);
                commit('promotions/setPromotionsAggregatedByScenario', [], {
                    root: true,
                });
            } else {
                await dispatch('setSelectedSubCampaign', subCampaign);
            }
        },
        async resetFieldsForSelectedSubCampaign({ dispatch, commit }, { subCampaign }) {
            await dispatch('scenarios/setSelectedScenarioId', null, { root: true });
            commit('setSelectedResourceDefinitionKey', null);
            commit('setSelectedResourceDefinitionPageNumber', null);

            await dispatch('setSelectedSubCampaignId', subCampaign);
        },
        async fetchPromotionsWithFieldsForSubCampaign(
            { dispatch },
            { subCampaign, postNotifications = false }
        ) {
            const promotionFields = [
                '_id',
                'name',
                'storeGroups',
                'resources',
                'workflowState',
                'parentPromotionId',
                'products.clientProductKey',
                'products.name',
                'products.category',
                'products.clientSupplierKey',
                'products.supplierName',
                'execution',
                'clientState',
                'startDate',
                'endDate',
                'tags',
                'scenarioId',
                'categories',
                'userSelectedCategories',
                'forecastingAggregations',
                'offerMechanic.description',
                'productOfferGroups',
                'effectivenessRating',
                'isGhost',
                'isOrphan',
                'notification',
                'isInManualMode',
                ...(postNotifications && [
                    'products.hierarchy',
                    'products.forecastingAggregations',
                    'products.forecasts',
                    'products.funding',
                ]),
            ];
            // It's important that fetchSubCampaignWithDependencies runs to completion before dispatching
            // the other actions because this ensures the all attributes of the sub-campaign are available.
            // Without all the attributes available, some computeds may error when recalculating.
            await dispatch('fetchSubCampaignWithDependencies', {
                subCampaign,
                promotionFields,
            });
        },
        async setSelectedSubCampaign({ dispatch }, subCampaign) {
            await dispatch('fetchPromotionsWithFieldsForSubCampaign', { subCampaign });

            await dispatch('resetFieldsForSelectedSubCampaign', { subCampaign });

            await dispatch('promotions/fetchSelectedSubcampaignPromotionAggregations', null, {
                root: true,
            });

            this.$app.globalEmit('focus-detail-component');
        },

        async refreshSubCampaign({ dispatch }, { subCampaign }) {
            await dispatch('fetchPromotionsWithFieldsForSubCampaign', {
                subCampaign,
                postNotifications: true,
            });
            await dispatch('promotions/fetchSelectedSubcampaignPromotionAggregations', null, {
                root: true,
            });
        },

        setUpdatedResourceDefinitions({ commit }, updatedResourceDefinitions) {
            commit('setUpdatedResourceDefinitions', updatedResourceDefinitions);
        },

        async updateSubCampaignResourceDefinitions({ dispatch, commit, state }) {
            // if we have updated the resourceDefinitions of the chosen subcampaign
            // make sure they are updated.
            const subCampaignId = get(state.updatedResourceDefinitions, 'subCampaignId', null);
            const updatedResourceDefinitions = get(
                state.updatedResourceDefinitions,
                'updatedResourceDefinitions',
                null
            );
            if (!isEmpty(updatedResourceDefinitions) && subCampaignId !== null) {
                await dispatch('updateSubCampaign', {
                    id: subCampaignId,
                    updates: { resourceDefinitions: updatedResourceDefinitions },
                });
            }
            commit('setUpdatedResourceDefinitions', null);
        },
    },
};

const mixinParams = {
    resource: 'sub-campaign',
    useForm: true,
    useNotes: true,
    useFilters: true,
    useWorkflow: true,
    useNominationMatrix: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
