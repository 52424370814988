<template>
    <v-dialog ref="dialog" v-model="isDialogOpen">
        <template v-if="hasActivator" v-slot:activator="{ on }">
            <div class="review-changes__banner">
                <span class="lock-icon">
                    <icon icon-name="parent-child-icon-red" />
                </span>
                <span class="lock-reason">
                    <strong>{{ $t('general.alerts.locked') | toSentenceCase }}</strong>
                    {{ $t('planning.promotionsViewer.notificationExist') | toSentenceCase }}
                </span>
                <v-btn
                    :disabled="isDisabled"
                    depressed
                    class="review-changes__button"
                    v-on="on"
                    @click="openDialog"
                >
                    {{ $tkey('reviewChanges') | toSentenceCase }}
                </v-btn>
            </div>
        </template>
        <v-card class="dialog-card" tile>
            <header class="dialog-card__header">
                <h1 class="dialog-card__title">
                    {{ $tkey('headings.general') | toSentenceCase }}
                </h1>
                <v-btn icon @click="closeDialog">
                    <icon icon-name="cancel" small icon-colour-type="primary-path" />
                </v-btn>
            </header>
            <div class="review-changes__description">
                <spinner-dynamic v-if="isDisabled" />
                {{ $tkey('descriptions.general') | toSentenceCase }}
            </div>
            <div class="review-changes__grids">
                <template v-if="hasMechanicChanges">
                    <h1 class="review-changes__title">
                        {{ $tkey('gridHeadings.offerMechanic') | toSentenceCase }}
                    </h1>
                    <offer-mechanic-changes
                        :child="offerMechanic"
                        :parent="changeset.changeset.offer"
                        :select-all-parent="toggleAllParent"
                        :select-all-child="toggleAllChild"
                        @select-offer-mechanic="selectOfferMechanic"
                    />
                </template>

                <template v-if="hasClientSpecificFieldsChanges">
                    <h1 class="review-changes__title">
                        {{ $tkey('gridHeadings.additionalClientSpecificFields') | toSentenceCase }}
                    </h1>
                    <client-specific-fields-changes
                        :child="commercialFields"
                        :parent="changeset.changeset.additionalClientSpecificFields"
                        :select-all-parent="toggleAllParent"
                        :select-all-child="toggleAllChild"
                        @select-client-specific-fields="selectClientSpecificFields"
                    />
                </template>

                <template v-if="hasProductChanges">
                    <h1 class="review-changes__title">
                        {{ $tkey('gridHeadings.products') | toSentenceCase }}
                    </h1>
                    <product-changes
                        :child="selectedPromotion.products"
                        :parent="changeset.changeset.products.products"
                        :select-all-parent="toggleAllParent"
                        :select-all-child="toggleAllChild"
                        @select-product="selectProduct"
                    />
                </template>

                <template v-if="hasFundingChanges">
                    <h1 class="review-changes__title">
                        {{ $tkey('gridHeadings.funding') | toSentenceCase }}
                    </h1>
                    <funding-changes
                        :child-products="selectedPromotion.products"
                        :parent-changeset="changeset.changeset.funding.va"
                        :select-all-parent="toggleAllParent"
                        :select-all-child="toggleAllChild"
                        @select-funding="selectFunding"
                    />
                </template>
            </div>
            <footer class="dialog-card__footer">
                <div class="buttons">
                    <v-btn depressed outlined data-id-e2e="btnCancelId" @click="closeDialog">
                        {{ $t('actions.cancel') | toSentenceCase }}
                    </v-btn>
                    <v-btn
                        depressed
                        outlined
                        class="review-changes-btn--child"
                        @click="selectAllChild"
                    >
                        {{ $t('planning.parentChanges.selectAllChild') | toSentenceCase }}
                    </v-btn>
                    <v-btn
                        depressed
                        outlined
                        class="review-changes-btn--parent"
                        @click="selectAllParent"
                    >
                        {{ $t('planning.parentChanges.selectAllParent') | toSentenceCase }}
                    </v-btn>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: criteria, attrs }">
                            <span class="tooltip-wrapper" v-bind="attrs" v-on="criteria">
                                <v-btn
                                    depressed
                                    outlined
                                    class="review-changes-btn--apply"
                                    :disabled="isDisabled || confirmBtnDisabled"
                                    data-id-e2e="btnSubmitId"
                                    @click="applyChanges"
                                >
                                    {{ $t('planning.parentChanges.apply') | toSentenceCase }}
                                </v-btn>
                            </span>
                        </template>
                        {{
                            (disabledReason || $t('planning.parentChanges.tooltips.apply'))
                                | toSentenceCase
                        }}
                    </v-tooltip>
                </div>
            </footer>

            <v-overlay
                v-if="isDisabled"
                class="review-changes__overlay"
                :absolute="true"
                :value="true"
                :opacity="0.3"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import to from 'await-to-js';
import { mapState, mapActions, mapGetters } from 'vuex';
import { isEmpty, uniq, cloneDeep } from 'lodash';
import UXEvents from '@enums/ux-events';
import { defaultChangeset } from '@sharedModules/changeset-utils';

export default {
    localizationKey: 'planning.parentChanges',
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        promotionId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDialogOpen: false,
            changeset: {
                changeset: defaultChangeset,
            },
            selectedMechanic: {},
            additionalClientSpecificFields: {},
            selectedFunding: {},
            selectedProducts: { added: {}, removed: {} },
            toggleAllChild: false,
            toggleAllParent: false,
        };
    },

    computed: {
        ...mapGetters('promotions', ['selectedPromotion']),
        ...mapState('promotions', ['changesetApplyInProgress']),
        ...mapState('clientConfig', ['generalConfig']),

        isDisabled() {
            return !!this.changesetApplyInProgress[this.promotionId];
        },

        confirmBtnDisabled() {
            return this.maxProductsReached();
        },

        disabledReason() {
            if (this.confirmBtnDisabled && this.maxProductsReached()) {
                return this.$t('validation.failureMessages.promotionMaxProducts', {
                    maxProductsPerPromotion: this.selectedPromotion.splitPromotion
                        ? this.generalConfig.maxProductsPerSplitPromotion
                        : this.generalConfig.maxProductsPerPromotion,
                });
            }

            return null;
        },

        hasMechanicChanges() {
            return this.changeset.changeset.offer && !isEmpty(this.changeset.changeset.offer);
        },
        hasClientSpecificFieldsChanges() {
            return !isEmpty(this.changeset.changeset.additionalClientSpecificFields);
        },
        hasFundingChanges() {
            return (
                this.changeset.changeset.funding.va && !isEmpty(this.changeset.changeset.funding.va)
            );
        },
        hasProductChanges() {
            return (
                !isEmpty(this.changeset.changeset.products.products.added) ||
                !isEmpty(this.changeset.changeset.products.products.removed)
            );
        },
        commercialFields() {
            return {
                offerCommercialField: this.selectedPromotion.additionalClientSpecificFields
                    .offerCommercialField,
                couponBarcode: this.selectedPromotion.offerMechanic.couponBarcode,
            };
        },
        offerMechanic() {
            const copyOfferMechanic = cloneDeep(this.selectedPromotion.offerMechanic);
            delete copyOfferMechanic.couponBarcodes;
            return copyOfferMechanic;
        },
    },

    methods: {
        ...mapActions('promotions', ['fetchPromotionChangeset', 'applyPromotionChangeset']),

        async openDialog() {
            // Only open the modal if a valid changeset is fetched
            const isValidChangeset = await this.fetchChangeset();
            this.isDialogOpen = isValidChangeset;
        },

        closeDialog() {
            this.isDialogOpen = false;
            this.resetDefaultValues();
        },

        resetDefaultValues() {
            this.selectedMechanic = {};
            this.selectedFunding = {};
            this.selectedProducts = { added: {}, removed: {} };
            this.changeset = {
                changeset: defaultChangeset,
            };
            this.toggleAllChild = false;
            this.toggleAllParent = false;
        },

        selectOfferMechanic(offer) {
            this.selectedMechanic = offer || {};
        },

        selectClientSpecificFields(specificFields) {
            this.additionalClientSpecificFields = specificFields || {};
        },

        selectFunding(va) {
            const { value, productKey } = va;
            if (!value) {
                // Child chosen
                delete this.selectedFunding[`${productKey}`];
            } else {
                // Parent chosen
                this.selectedFunding[`${productKey}`] = value;
            }
        },

        selectProduct(product) {
            const { value, isAdded, productKey } = product;
            if (!value) {
                // Child chosen
                if (isAdded) {
                    delete this.selectedProducts.added[`${productKey}`];
                } else {
                    delete this.selectedProducts.removed[`${productKey}`];
                }
            }
            // Parent chosen
            else if (isAdded) {
                this.selectedProducts.added[`${productKey}`] = value;
            } else {
                this.selectedProducts.removed[`${productKey}`] = value;
            }
        },

        maxProductsReached() {
            const addedProducts = Object.values(this.selectedProducts.added);
            const removedProducts = Object.values(this.selectedProducts.removed);

            const totalProducts =
                this.selectedPromotion.products.length +
                addedProducts.length -
                removedProducts.length;

            // disable apply button if the maximum number of products was reached
            if (this.selectedPromotion.splitPromotion) {
                return totalProducts >= this.generalConfig.maxProductsPerSplitPromotion;
            }

            return (
                this.generalConfig.maxProductsPerPromotion !== -1 &&
                totalProducts >= this.generalConfig.maxProductsPerPromotion
            );
        },

        async applyChanges() {
            // Update products list with the accepted added and removed products
            const added = Object.values(this.selectedProducts.added);
            const removed = Object.values(this.selectedProducts.removed);

            // Update pogs with added and removed product keys
            this.changeset.changeset.topLevelFields.productOfferGroups.same = this.changeset.changeset.topLevelFields.productOfferGroups.same.map(
                offerGroup => {
                    const addedKeys = added.map(product => product.productKey);
                    const removedKeys = removed.map(product => product.productKey);

                    offerGroup.products = uniq(
                        [...offerGroup.products, ...addedKeys].filter(
                            key => !removedKeys.includes(key)
                        )
                    );
                    return offerGroup;
                }
            );

            const changeset = {
                ...this.changeset.changeset,
                offer: this.selectedMechanic,
                additionalClientSpecificFields: this.additionalClientSpecificFields,
                funding: { ...this.changeset.changeset.funding, va: this.selectedFunding },
                products: {
                    ...this.changeset.changeset.products,
                    products: {
                        added,
                        removed,
                    },
                },
            };

            const [error] = await to(
                this.applyPromotionChangeset({
                    parentPromotionId: this.changeset.promotionAId,
                    childPromotionId: this.promotionId,
                    parentVersionReference: this.selectedPromotion.notification
                        .parentVersionReference,
                    changeset,
                    changesetId: this.changeset._id,
                })
            );

            if (!error) {
                if (!isEmpty(added)) {
                    this.globalEmit(UXEvents.promotionProductsUpdatedForGridRefresh, {
                        products: added,
                        added: true,
                        fetchProductsAndAttributesForPromotionProductOfferGroup: true,
                    });
                }
                if (!isEmpty(removed)) {
                    this.globalEmit(UXEvents.promotionProductsUpdatedForGridRefresh, {
                        products: removed.map(p => p.productKey),
                        added: false,
                        fetchProductsAndAttributesForPromotionProductOfferGroup: true,
                    });
                }
                this.closeDialog();
            }
        },

        async fetchChangeset() {
            // Generate changeset based on version reference and current parent version
            const changeset = await this.fetchPromotionChangeset({
                promotionId: this.promotionId,
                parentId: this.selectedPromotion.parentPromotionId,
                versionReference: this.selectedPromotion.notification.parentVersionReference,
            });

            if (!changeset || !changeset.changeset) {
                // If a reverted or invalid changeset is fetched, run apply to clear notification and prevent the modal from opening
                await this.applyPromotionChangeset({
                    parentPromotionId: this.selectedPromotion.parentPromotionId,
                    childPromotionId: this.promotionId,
                    parentVersionReference: this.selectedPromotion.notification
                        .parentVersionReference,
                    changeset: this.changeset.changeset,
                    changesetId: this.changeset._id,
                });

                return false;
            }

            this.changeset = changeset;
            this.selectedFunding = cloneDeep(this.changeset.changeset.funding.va);
            this.selectedMechanic = cloneDeep(this.changeset.changeset.offer);
            this.additionalClientSpecificFields = cloneDeep(
                this.changeset.changeset.additionalClientSpecificFields
            );
            const addedProducts = this.changeset.changeset.products.products.added.reduce(
                (acc, p) => {
                    return { ...acc, [`${p.clientProductKey}`]: p };
                },
                {}
            );
            const removedProducts = this.changeset.changeset.products.products.removed.reduce(
                (acc, p) => {
                    return { ...acc, [`${p.clientProductKey}`]: p };
                },
                {}
            );
            this.selectedProducts = { added: addedProducts, removed: removedProducts };

            return true;
        },

        selectAllChild() {
            this.toggleAllChild = !this.toggleAllChild;
        },

        selectAllParent() {
            this.toggleAllParent = !this.toggleAllParent;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.review-changes {
    &__description {
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;

        box-shadow: 0 6px 6px -6px $promo-black;
        margin-bottom: 1rem;

        background-color: $promo-white;
    }

    &__grids {
        padding: 1rem;
        max-height: 70rem;
        overflow-y: scroll;
        background-color: $promo-table-blue-bg-colour;
    }

    &__banner {
        height: 6rem;
        margin: 1rem;
        padding: 0.5rem 1rem;

        background-color: $alert-banner-error;
        border: 0.1rem solid $alert-button-error;

        .lock-icon {
            font-size: 2.8rem;
            margin-right: 0.5rem;
            svg path,
            svg text {
                fill: $alert-button-error;
            }
        }
        .lock-reason {
            font-size: 1.4rem;
            color: $promo-black;
        }
    }

    &__button {
        margin: 1rem;
        height: 3.4rem !important;
        font-size: 1.6rem !important;

        background-color: $alert-button-error !important;
        color: $promo-white;
    }

    &__title {
        font-family: 'Source Sans Pro';
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 600;
        letter-spacing: 0;
        color: $promo-text-colour;
        margin-bottom: 1rem;
    }

    &__overlay {
        z-index: 99 !important;
        grid-column-start: 1;
        grid-column-end: -1;
    }
}

.dialog-card {
    border-left: 0.4rem solid $promo-primary-colour;
    border-radius: 0;
    padding: 0;
    min-width: 144rem;

    overflow: hidden;

    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: $promo-table-blue-bg-colour;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 4rem;
        min-height: 4rem;
        border-bottom: 1px solid $promo-divider-colour;

        padding: 1rem;
        background-color: $promo-white;
    }

    &__title {
        font-family: 'Source Sans Pro';
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 600;
        letter-spacing: 0;
        color: $promo-text-colour;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: 80vh;
        overflow: auto;
    }

    &__footer {
        height: 5.5rem;
        padding: 1rem;
        margin-top: 1rem;
        align-items: center;
        border-top: 1px solid $promo-divider-colour;
        box-shadow: 0 -6px 6px -6px $promo-black;
    }

    .buttons {
        width: 100%;
        text-align: right;
        float: right;

        .v-btn {
            margin: 0 0.5rem;

            &--disabled {
                color: $promo-white !important;
                border: 0.1rem solid $promo-grey-disabled-3 !important;
                background-color: $promo-grey-disabled-3 !important;
            }
        }

        .tooltip-wrapper {
            height: fit-content;
            width: fit-content;
            display: inline-block;
            cursor: default;
        }

        .review-changes-btn {
            &--child {
                color: $promo-white;
                border: 0.1rem solid $child-changes;
                background-color: $child-changes;
            }

            &--parent {
                color: $promo-white;
                border: 0.1rem solid $parent-changes;
                background-color: $parent-changes;
            }

            &--apply {
                color: $promo-white;
                border: 0.1rem solid $promo-primary-colour;
                background-color: $promo-primary-colour;
            }
        }
    }
}

::v-deep {
    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%;
    }
}
</style>
