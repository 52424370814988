import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VExpansionPanels,
    { staticClass: "rtls-expansion-panels", attrs: { theme: "primary" } },
    [
      _c(
        VExpansionPanel,
        { staticClass: "rtls-expansion-panel" },
        [
          _c(
            VExpansionPanelHeader,
            { staticClass: "rtls-expansion-panel-header" },
            [
              _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "rtls-expansion-panel-header__label" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(_vm.$tkey("header"))
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isCompleted
                    ? _c("icon", {
                        attrs: {
                          "icon-name": "empty-success-circle",
                          small: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VExpansionPanelContent,
            { staticClass: "rtls-expansion-panel-content" },
            [
              _c("div", { staticClass: "offer-restrictions" }, [
                _c(
                  "div",
                  { staticClass: "offer-restrictions__group" },
                  [
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item" },
                      [
                        _c("vuex-checkbox", {
                          staticClass: "checkbox",
                          attrs: {
                            getter: _vm.maximumPerCustomerCheckboxGetter,
                            setter: _vm.maximumPerCustomerCheckboxSetter,
                            label: _vm._f("toSentenceCase")(
                              _vm.$tkey("maxTriggerPerCustomer")
                            ),
                            disabled: _vm.isWeightPromotion,
                          },
                        }),
                        _vm._v(" "),
                        _c("vuex-number-input", {
                          staticClass:
                            "offer-restrictions__number-narrow max-trigger-per-customer-input",
                          attrs: {
                            "vuex-module": _vm.vuexModule,
                            tab: _vm.offer,
                            "field-path": "offerMechanic",
                            "field-name": "maximumPerCustomer",
                            height: "21",
                            namespace: _vm.namespace,
                            "form-ref": _vm.formRef,
                            "need-pre-validation-on-set": true,
                            validations: _vm.maxPerCustomerValidators,
                            disabled:
                              !_vm.maxPerCustomerEnabled ||
                              _vm.isWeightPromotion,
                            "reset-value-on": _vm.clearMaxPerCustomerEventName,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item" },
                      [
                        _c("vuex-checkbox", {
                          staticClass: "checkbox",
                          attrs: {
                            getter: _vm.maximumPerPurchaseCheckboxGetter,
                            setter: _vm.maximumPerPurchaseCheckboxSetter,
                            label: _vm._f("toSentenceCase")(
                              _vm.$tkey("maxTriggerPerPurchase")
                            ),
                            disabled: _vm.isWeightPromotion,
                          },
                        }),
                        _vm._v(" "),
                        _c("vuex-number-input", {
                          staticClass:
                            "offer-restrictions__number-narrow max-trigger-per-customer-input",
                          attrs: {
                            "vuex-module": _vm.vuexModule,
                            tab: _vm.offer,
                            "field-path": "offerMechanic",
                            "field-name": "maximumPerPurchase",
                            height: "21",
                            namespace: _vm.namespace,
                            "form-ref": _vm.formRef,
                            "need-pre-validation-on-set": true,
                            validations: _vm.maxPerPurchaseValidators,
                            disabled:
                              !_vm.maxPerPurchaseEnabled ||
                              _vm.isWeightPromotion,
                            "reset-value-on": _vm.clearMaxPerPurchaseEventName,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "feature-toggle",
                      { attrs: { toggle: _vm.showSingleTrip } },
                      [
                        _c(VDivider),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "offer-restrictions__stretch-offer" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$tkey("storeVisitCondition")
                                  )
                                ) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("vuex-radio-group", {
                              attrs: {
                                "vuex-module": _vm.vuexModule,
                                tab: _vm.offer,
                                "field-path": "offerMechanic",
                                "field-name": "isSingleTripPromo",
                                namespace: _vm.namespace,
                                options: _vm.singleTripPromoOptions,
                                row: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "feature-toggle",
                      { attrs: { toggle: _vm.allVariantsFlag } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "offer-restrictions__all-variants-flag",
                          },
                          [
                            _c(VDivider),
                            _vm._v(" "),
                            _c("vuex-checkbox", {
                              attrs: {
                                "vuex-module": _vm.vuexModule,
                                tab: _vm.offer,
                                "field-path": "additionalClientSpecificFields",
                                "field-name": "allVariantsFlag",
                                namespace: _vm.namespace,
                                label: _vm._f("toSentenceCase")(
                                  _vm.$tkey("allVariants")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "feature-toggle",
                      { attrs: { toggle: _vm.enableOfferCommercialField } },
                      [
                        _c(VDivider),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "offer-restrictions__commercial-field",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "offer-restrictions__item--label",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$tkey("commercial")
                                      )
                                    ) +
                                    ":\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "offer-restrictions__item" },
                              [
                                _c("vuex-select", {
                                  attrs: {
                                    filled: "",
                                    placeholder: _vm.$tkey("commercial"),
                                    multiple: false,
                                    options: _vm.offerCommercialFieldsOptions,
                                    "vuex-module": _vm.vuexModule,
                                    tab: _vm.offer,
                                    "field-path":
                                      "additionalClientSpecificFields",
                                    "field-name": "offerCommercialField",
                                    namespace: _vm.namespace,
                                    clearable: "",
                                    "update-undefined-to-null": "",
                                  },
                                  on: { change: _vm.onChangeCommercialField },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "offer-restrictions__group offer-restrictions__group--right",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item--label" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$tkey("availability")
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item" },
                      [
                        _c("vuex-select", {
                          attrs: {
                            filled: "",
                            placeholder: _vm.$tkey("to"),
                            multiple: false,
                            options: _vm.customerAvailabilityOptions,
                            "vuex-module": _vm.vuexModule,
                            tab: _vm.offer,
                            "field-path": "offerMechanic",
                            "field-name": "customerAvailability",
                            namespace: _vm.namespace,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item" },
                      [
                        _c("vuex-select", {
                          attrs: {
                            filled: "",
                            placeholder: _vm.$tkey("how"),
                            multiple: false,
                            options: _vm.promotionAvailabilityOptions,
                            "vuex-module": _vm.vuexModule,
                            tab: _vm.offer,
                            "field-path": "offerMechanic",
                            "field-name": "promotionAvailability",
                            namespace: _vm.namespace,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item--label" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$tkey("withCoupon"))
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "offer-restrictions__item" },
                      [
                        !_vm.isCouponLinkedWithCommercialField
                          ? [
                              _c("vuex-checkbox", {
                                staticClass: "checkbox coupon-barcode-input",
                                attrs: {
                                  getter: _vm.couponEnabledCheckboxGetter,
                                  setter: _vm.couponEnabledCheckboxSetter,
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("vuex-number-input", {
                          staticClass:
                            "offer-restrictions__number-wide coupon-barcode-input",
                          attrs: {
                            "vuex-module": _vm.vuexModule,
                            tab: _vm.offer,
                            "field-path": "offerMechanic",
                            "field-name": "couponBarcode",
                            height: "21",
                            namespace: _vm.namespace,
                            validations: _vm.couponBarcodeValidators,
                            disabled: _vm.couponDisabled,
                            "reset-value-on": _vm.clearBarcodeCouponEventName,
                            "form-ref": _vm.formRef,
                            "need-pre-validation-on-set": true,
                            "save-as-string": true,
                            format: "numbers.default.justNumeric",
                          },
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }