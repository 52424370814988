<template>
    <v-expansion-panels class="rtls-expansion-panels" theme="primary">
        <v-expansion-panel class="rtls-expansion-panel">
            <v-expansion-panel-header class="rtls-expansion-panel-header">
                <div>
                    <span class="rtls-expansion-panel-header__label">
                        {{ $tkey('header') | toSentenceCase }}
                    </span>
                    <icon v-if="isCompleted" icon-name="empty-success-circle" small />
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="rtls-expansion-panel-content">
                <div class="offer-restrictions">
                    <div class="offer-restrictions__group">
                        <div class="offer-restrictions__item">
                            <vuex-checkbox
                                :getter="maximumPerCustomerCheckboxGetter"
                                :setter="maximumPerCustomerCheckboxSetter"
                                :label="$tkey('maxTriggerPerCustomer') | toSentenceCase"
                                :disabled="isWeightPromotion"
                                class="checkbox"
                            />
                            <vuex-number-input
                                :vuex-module="vuexModule"
                                :tab="offer"
                                field-path="offerMechanic"
                                field-name="maximumPerCustomer"
                                height="21"
                                :namespace="namespace"
                                :form-ref="formRef"
                                :need-pre-validation-on-set="true"
                                :validations="maxPerCustomerValidators"
                                class="offer-restrictions__number-narrow max-trigger-per-customer-input"
                                :disabled="!maxPerCustomerEnabled || isWeightPromotion"
                                :reset-value-on="clearMaxPerCustomerEventName"
                            />
                        </div>
                        <div class="offer-restrictions__item">
                            <vuex-checkbox
                                :getter="maximumPerPurchaseCheckboxGetter"
                                :setter="maximumPerPurchaseCheckboxSetter"
                                :label="$tkey('maxTriggerPerPurchase') | toSentenceCase"
                                class="checkbox"
                                :disabled="isWeightPromotion"
                            />
                            <vuex-number-input
                                :vuex-module="vuexModule"
                                :tab="offer"
                                field-path="offerMechanic"
                                field-name="maximumPerPurchase"
                                height="21"
                                :namespace="namespace"
                                :form-ref="formRef"
                                :need-pre-validation-on-set="true"
                                :validations="maxPerPurchaseValidators"
                                class="offer-restrictions__number-narrow max-trigger-per-customer-input"
                                :disabled="!maxPerPurchaseEnabled || isWeightPromotion"
                                :reset-value-on="clearMaxPerPurchaseEventName"
                            />
                        </div>
                        <feature-toggle :toggle="showSingleTrip">
                            <v-divider />
                            <div class="offer-restrictions__stretch-offer">
                                <p>{{ $tkey('storeVisitCondition') | toSentenceCase }}:</p>
                                <vuex-radio-group
                                    :vuex-module="vuexModule"
                                    :tab="offer"
                                    field-path="offerMechanic"
                                    field-name="isSingleTripPromo"
                                    :namespace="namespace"
                                    :options="singleTripPromoOptions"
                                    row
                                />
                            </div>
                        </feature-toggle>
                        <feature-toggle :toggle="allVariantsFlag">
                            <div class="offer-restrictions__all-variants-flag">
                                <v-divider />
                                <vuex-checkbox
                                    :vuex-module="vuexModule"
                                    :tab="offer"
                                    field-path="additionalClientSpecificFields"
                                    field-name="allVariantsFlag"
                                    :namespace="namespace"
                                    :label="$tkey('allVariants') | toSentenceCase"
                                />
                            </div>
                        </feature-toggle>
                        <feature-toggle :toggle="enableOfferCommercialField">
                            <v-divider />
                            <div class="offer-restrictions__commercial-field">
                                <div class="offer-restrictions__item--label">
                                    {{ $tkey('commercial') | toSentenceCase }}:
                                </div>
                                <div class="offer-restrictions__item">
                                    <vuex-select
                                        filled
                                        :placeholder="$tkey('commercial')"
                                        :multiple="false"
                                        :options="offerCommercialFieldsOptions"
                                        :vuex-module="vuexModule"
                                        :tab="offer"
                                        field-path="additionalClientSpecificFields"
                                        field-name="offerCommercialField"
                                        :namespace="namespace"
                                        clearable
                                        update-undefined-to-null
                                        @change="onChangeCommercialField"
                                    />
                                </div>
                            </div>
                        </feature-toggle>
                    </div>
                    <div class="offer-restrictions__group offer-restrictions__group--right">
                        <div class="offer-restrictions__item--label">
                            {{ $tkey('availability') | toSentenceCase }}
                        </div>
                        <div class="offer-restrictions__item">
                            <vuex-select
                                filled
                                :placeholder="$tkey('to')"
                                :multiple="false"
                                :options="customerAvailabilityOptions"
                                :vuex-module="vuexModule"
                                :tab="offer"
                                field-path="offerMechanic"
                                field-name="customerAvailability"
                                :namespace="namespace"
                            />
                        </div>
                        <div class="offer-restrictions__item">
                            <vuex-select
                                filled
                                :placeholder="$tkey('how')"
                                :multiple="false"
                                :options="promotionAvailabilityOptions"
                                :vuex-module="vuexModule"
                                :tab="offer"
                                field-path="offerMechanic"
                                field-name="promotionAvailability"
                                :namespace="namespace"
                            />
                        </div>
                        <div class="offer-restrictions__item--label">
                            {{ $tkey('withCoupon') | toSentenceCase }}
                        </div>
                        <div class="offer-restrictions__item">
                            <template v-if="!isCouponLinkedWithCommercialField">
                                <vuex-checkbox
                                    :getter="couponEnabledCheckboxGetter"
                                    :setter="couponEnabledCheckboxSetter"
                                    class="checkbox coupon-barcode-input"
                                />
                            </template>
                            <vuex-number-input
                                :vuex-module="vuexModule"
                                :tab="offer"
                                field-path="offerMechanic"
                                field-name="couponBarcode"
                                height="21"
                                :namespace="namespace"
                                :validations="couponBarcodeValidators"
                                :disabled="couponDisabled"
                                :reset-value-on="clearBarcodeCouponEventName"
                                :form-ref="formRef"
                                :need-pre-validation-on-set="true"
                                :save-as-string="true"
                                format="numbers.default.justNumeric"
                                class="offer-restrictions__number-wide coupon-barcode-input"
                            />
                        </div>
                    </div>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { get, intersectionWith, isEmpty } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import promotionAvailability from '@enums/promotion-availability';
import { offer } from '@enums/promotion-tabs';
import {
    showSingleTrip,
    allVariantsFlag,
    enableOfferCommercialField,
    couponLinkedWithCommercialField,
} from '@enums/feature-flags';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import validators from '@/js/validators';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    mixins: [vuexComponentMixin],
    localizationKey: 'planning.promotionsMaintenance.offer.offerMechanic.restrictions',

    props: {
        formRef: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            maxPerCustomerEnabled: false,
            maxPerPurchaseEnabled: false,
            couponEnabled: false,
            clearMaxPerCustomerEventName: 'clearMaxPerCustomer',
            clearMaxPerPurchaseEventName: 'clearMaxPerPurchase',
            clearBarcodeCouponEventName: 'clearBarcodeCoupon',
            showSingleTrip,
            allVariantsFlag,
            enableOfferCommercialField,
            offer,
        };
    },
    computed: {
        ...mapGetters('promotions', [
            'getCheckboxListOptions',
            'getStagingAreaField',
            'selectedPromotion',
            'getStagingAreaPromotionById',
        ]),
        ...mapState('clientConfig', {
            clientConfigValidations: state => state.validations,
        }),

        ...mapState('clientConfig', ['offerCommercialFields', 'toggleLogic']),

        promotion() {
            if (!isEmpty(this.selectedPromotion)) return this.selectedPromotion;

            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            return this.getStagingAreaPromotionById('default');
        },

        isWeightPromotion() {
            return get(this.promotion, 'isWeightPromotion', false);
        },

        offerMechanic() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'offerMechanic',
            });
        },

        additionalClientSpecificFields() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'additionalClientSpecificFields',
            });
        },

        couponDisabled() {
            if (this.toggleLogic[couponLinkedWithCommercialField]) {
                return !this.commercialFieldHasValue;
            }

            return !this.couponEnabled;
        },

        // This is a computed because these validation rules vary depending on whether or not the checkbox is checked.
        maxPerCustomerValidators() {
            return this.maxPerCustomerEnabled
                ? [
                      {
                          validator: validators.minValue,
                          params: [1],
                      },
                      {
                          validator: validators.maxValue,
                          params: [
                              this.clientConfigValidations.offerMechanicMaxPerCustomerCount.params
                                  .value,
                          ],
                      },
                  ]
                : [];
        },

        // This is a computed because these validation rules vary depending on whether or not the checkbox is checked.
        maxPerPurchaseValidators() {
            return this.maxPerPurchaseEnabled
                ? [
                      {
                          validator: validators.minValue,
                          params: [1],
                      },
                      {
                          validator: validators.maxValue,
                          params: [
                              this.clientConfigValidations.offerMechanicMaxPerPurchaseCount.params
                                  .value,
                          ],
                      },
                  ]
                : [];
        },
        coupuonCodeHasValue() {
            return this.offerMechanic.couponBarcode && this.offerMechanic.couponBarcode !== '-';
        },
        couponBarcodeValidators() {
            return !this.couponDisabled && this.coupuonCodeHasValue
                ? [
                      {
                          validator: validators.numericString,
                      },
                      {
                          validator: validators.maxLength,
                          params: [14],
                      },
                  ]
                : [];
        },

        customerAvailabilityOptions() {
            const allowedCustomerGroupsForScenario = this.context.selectedScenario.customerGroups;
            const allOptionsAvailableForScenario = this.getCheckboxListOptions({
                attributeName: 'customerGroups',
                attributeKey: 'key',
                resource: 'clientConfig',
                getOptionsFunction: 'getCustomerRestrictions',
                scenarioId: this.context.scenarioId,
            });
            const allowedOptionsForScenario =
                this.context.scenarioId ||
                (this.context.selectedScenario && this.context.selectedScenario._id)
                    ? intersectionWith(
                          allOptionsAvailableForScenario,
                          allowedCustomerGroupsForScenario,
                          (available, allowed) => {
                              return available.reference.key === allowed.key;
                          }
                      )
                    : [...allOptionsAvailableForScenario];

            return allowedOptionsForScenario.map(option => {
                return {
                    text: toSentenceCase(option.reference.description),
                    value: option.reference.key,
                };
            });
        },

        promotionAvailabilityOptions() {
            return [
                {
                    text: toSentenceCase(this.$tkey('promotionAvailabilities.onPurchase')),
                    value: promotionAvailability.onPurchase,
                },
                {
                    text: toSentenceCase(this.$tkey('promotionAvailabilities.handInCoupon')),
                    value: promotionAvailability.handInCoupon,
                },
            ];
        },

        isCompleted() {
            return (
                !!this.offerMechanic.maximumPerCustomer ||
                !!this.offerMechanic.maximumPerPurchase ||
                !!this.offerMechanic.couponBarcode ||
                !!this.offerMechanic.promotionAvailability ||
                (this.offerMechanic.customerAvailability &&
                    this.offerMechanic.customerAvailability !== 'allCustomers')
            );
        },
        singleTripPromoOptions() {
            return [
                {
                    value: true,
                    text: toSentenceCase(this.$tkey('single')),
                },
                {
                    value: false,
                    text: toSentenceCase(this.$tkey('multiple')),
                },
            ];
        },

        offerCommercialFieldsOptions() {
            return this.offerCommercialFields.map(ocf => {
                return {
                    text: toSentenceCase(this.$t(ocf.translationKey)),
                    value: ocf.field,
                };
            });
        },
        isCouponLinkedWithCommercialField() {
            return this.toggleLogic[couponLinkedWithCommercialField];
        },
        commercialFieldHasValue() {
            return !!this.additionalClientSpecificFields.offerCommercialField;
        },
    },

    watch: {
        offerMechanic() {
            this.initCheckboxes();
        },
    },

    async mounted() {
        this.initCheckboxes();
    },

    methods: {
        initCheckboxes() {
            // set up checkboxes for triggers
            this.maxPerCustomerEnabled = !!this.offerMechanic.maximumPerCustomer;
            this.maxPerPurchaseEnabled = !!this.offerMechanic.maximumPerPurchase;
            if (!this.toggleLogic[couponLinkedWithCommercialField]) {
                this.couponEnabled = !!this.offerMechanic.couponBarcode;
            }
        },

        maximumPerCustomerCheckboxGetter() {
            return this.maxPerCustomerEnabled;
        },

        maximumPerCustomerCheckboxSetter(value) {
            this.maxPerCustomerEnabled = value;
            if (!value) {
                // Reset the maximum quantity if no limit is required.
                this.globalEmit(this.clearMaxPerCustomerEventName);
            }
        },

        maximumPerPurchaseCheckboxGetter() {
            return this.maxPerPurchaseEnabled;
        },

        maximumPerPurchaseCheckboxSetter(value) {
            this.maxPerPurchaseEnabled = value;
            if (!value) {
                // Reset the maximum quantity if no limit is required.
                this.globalEmit(this.clearMaxPerPurchaseEventName);
            }
        },

        couponEnabledCheckboxGetter() {
            return this.couponEnabled;
        },

        couponEnabledCheckboxSetter(value) {
            this.couponEnabled = value;
            if (!value) {
                // Reset the value if required.
                this.globalEmit(this.clearBarcodeCouponEventName);
            }
        },
        onChangeCommercialField(value) {
            if (!value && this.toggleLogic[couponLinkedWithCommercialField]) {
                // Reset the value if required.
                this.globalEmit(this.clearBarcodeCouponEventName);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.rtls-expansion-panel {
    .rtls-expansion-panel-header {
        padding: 0;
        > * {
            flex-grow: 0;
        }

        &__label {
            color: $promo-primary-colour;
            font-size: 1.2rem;
            letter-spacing: 0;
            padding-right: 1rem;
        }
    }
}

.offer-restrictions {
    @include flex-row;

    &__number-narrow {
        max-width: 5rem;
        padding-left: 1rem;
    }

    &__number-wide {
        max-width: 13rem;
    }

    &__group {
        padding-right: 1.7rem;
        padding-left: 0.3rem;

        &--right {
            padding-left: 2rem;
            border-left: 0.1rem solid $promo-grey-4;
        }
    }

    &__item {
        padding-bottom: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--label {
            padding-bottom: 0.5rem;
        }

        .rtls-select-container {
            max-width: 15rem;
        }
    }

    &__stretch-offer {
        p {
            padding: 0.3rem 0;
        }

        ::v-deep {
            .v-input--selection-controls {
                margin-top: 0;
            }

            legend.v-label {
                padding-right: 0;
            }
        }
    }

    &__all-variants-flag {
        padding-top: 0.6rem;
        padding-bottom: 1rem;

        > div {
            margin-top: 0.5rem;
            padding-left: 0.3rem;
        }
    }

    &__commercial-field {
        div.offer-restrictions__item--label {
            padding-top: 0.3rem;
            padding-bottom: 0.5rem;
        }

        ::v-deep {
            .v-input--selection-controls {
                margin-top: 0;
            }
        }
    }
}

::v-deep {
    .v-expansion-panel-header__icon .v-icon {
        font-size: 2.8rem;
        color: $rtls-primary-colour !important;
    }
    .v-expansion-panel--active {
        .v-expansion-panel-header--active
            .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
            .v-icon {
            color: $promo-light-blue !important;
        }
        .v-expansion-panel-header {
            min-height: 4rem;
        }
    }
    .v-expansion-panel-header {
        min-height: 4rem;
    }
}
</style>
