<template>
    <div class="client-specific-fields-changes-viewer">
        <div class="client-specific-fields-changes-viewer__grid">
            <promo-ag-grid
                ref="agGrid"
                :row-data="rowData"
                :column-defs="columnDefs"
                :grid-options="gridOptions"
                :default-col-def="defaultColDef"
                :grid-style="gridStyle"
                :grid-class="gridClass"
                dom-layout="autoHeight"
            />
        </div>
        <div class="client-specific-fields-changes-viewer__actions" />
    </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import { mapState } from 'vuex';
import parentChildTypes from '@enums/parent-child';
import { toSentenceCase } from '@/js/utils/string-utils';
import ButtonField from '@/js/components/promo-ag-grid/ag-button-field';

const parentChangesLocalizationKey = 'planning.parentChanges';
const acceptCellClasses = ['parent-child', 'parent-child__button'];
const acceptCellType = 'parentChildButton';

export default {
    props: {
        // Child client specific fields
        child: {
            type: Object,
            default: null,
        },
        // Parent changeset client specific fields
        parent: {
            type: Object,
            default: null,
        },
        selectAllChild: {
            type: Boolean,
            default: false,
        },
        selectAllParent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                filter: true,
                menuTabs: [],
            },
            gridOptions: {
                suppressContextMenu: true, // only show filter
                rowHeight: 35, // Specified in pixels.
                columnTypes: {
                    parentChildButton: {
                        headerName: '',
                        headerHeight: 0,
                        filter: false,
                        maxWidth: 70,
                        cellRendererFramework: ButtonField,
                        cellRendererParams: {
                            onClick: params => {
                                this.selectChanges(params.value);
                            },
                            visible: params => params.rowIndex === 0,
                        },
                    },
                },
            },
            gridStyle: 'width: 144rem; height: 100%;',
            gridClass: 'ag-theme-custom__parent-child-changes',
            headerColumns: [
                parentChildTypes.child,
                parentChildTypes.parent,
                parentChildTypes.accept,
            ],
            fieldName: 'offerCommercialField',
            selectedChangeset: parentChildTypes.parent,
            acceptChildButtonText: toSentenceCase(
                this.$tkey(`${parentChangesLocalizationKey}.gridHeadings.${parentChildTypes.child}`)
            ),
            acceptParentButtonText: toSentenceCase(
                this.$tkey(
                    `${parentChangesLocalizationKey}.gridHeadings.${parentChildTypes.parent}`
                )
            ),
        };
    },
    computed: {
        ...mapState('clientConfig', ['offerCommercialFields']),
        offerCommercialFieldsMap() {
            return this.offerCommercialFields.reduce((acc, { field, translationKey }) => {
                acc[field] = translationKey;
                return acc;
            }, {});
        },
        rowData() {
            if (!this.parent || isEmpty(this.parent)) {
                return [];
            }
            const offerClientSpecificFieldsRow = this.headerColumns.reduce((acc, header) => {
                const fieldValue = {
                    offerCommercialField: get(this, `${header}.offerCommercialField`),
                    couponBarcode: get(this, `${header}.couponBarcode`),
                };
                acc[`${header}`] = {
                    fieldName: this.fieldName,
                    fieldValue,
                };
                return acc;
            }, {});

            return [offerClientSpecificFieldsRow];
        },

        columnDefs() {
            const checkIfAcceptButtonSelected = params =>
                this.selectedChangeset === params.colDef.cellRendererParams.value;

            return this.headerColumns.map(header => {
                const children = [];

                if (header === parentChildTypes.accept) {
                    children.push(
                        {
                            fieldName: 'childButton',
                            type: acceptCellType,
                            cellClass: [...acceptCellClasses, 'parent-child__button--child'],
                            cellClassRules: {
                                'parent-child__button--child-selected': checkIfAcceptButtonSelected,
                            },
                            cellRendererParams: {
                                text: this.acceptChildButtonText,
                                value: parentChildTypes.child,
                            },
                        },
                        {
                            fieldName: 'parentButton',
                            type: acceptCellType,
                            cellClass: [...acceptCellClasses, 'parent-child__button--parent'],
                            cellClassRules: {
                                'parent-child__button--parent-selected': checkIfAcceptButtonSelected,
                            },
                            cellRendererParams: {
                                text: this.acceptParentButtonText,
                                value: parentChildTypes.parent,
                            },
                        }
                    );
                } else {
                    const commonCellsOptions = {
                        headerName: '',
                        headerHeight: 0,
                        cellClass: ['parent-child', `border-left__${header}`],
                        cellClassRules: {
                            'parent-child--selected': () => this.selectedChangeset === header,
                            'parent-child--not-selected': () => this.selectedChangeset !== header,
                        },
                    };

                    children.push(
                        {
                            ...commonCellsOptions,
                            field: `${header}.fieldName`,
                            maxWidth: 200,
                            cellRenderer: params => {
                                const cellText = this.$tkey(
                                    `${parentChangesLocalizationKey}.gridFields.offerClientSpecificFields.${
                                        params.value
                                    }`
                                );
                                return `<label>${toSentenceCase(cellText)}</label>`;
                            },
                        },
                        {
                            ...commonCellsOptions,
                            field: `${header}.fieldValue`,
                            maxWidth: 450,
                            autoHeight: true,
                            cellRenderer: params => {
                                const offerCommercialFieldValue = params.value.offerCommercialField
                                    ? this.$tkey(
                                          this.offerCommercialFieldsMap[
                                              params.value.offerCommercialField
                                          ]
                                      )
                                    : '-';
                                const couponBarcode = params.value.couponBarcode || '-';
                                return `
                                    <div>${toSentenceCase(
                                        this.$tkey(
                                            `${parentChangesLocalizationKey}.gridFields.offerClientSpecificFields.offerCommercialField`
                                        )
                                    )}: ${offerCommercialFieldValue}</div>
                                    <div>${toSentenceCase(
                                        this.$tkey(
                                            `${parentChangesLocalizationKey}.gridFields.restrictions.coupon`
                                        )
                                    )} ${couponBarcode}</div>
                                `;
                            },
                        }
                    );
                }

                return {
                    headerName: toSentenceCase(
                        this.$tkey(`${parentChangesLocalizationKey}.gridHeadings.${header}`)
                    ),
                    headerClass: `border-left__${header}`,
                    minWidth: 410,
                    autoHeight: true,
                    children,
                };
            });
        },
    },
    watch: {
        selectAllChild() {
            this.toggleSelection(parentChildTypes.child);
        },
        selectAllParent() {
            this.toggleSelection(parentChildTypes.parent);
        },
    },
    methods: {
        selectChanges(value) {
            this.selectedChangeset = value;
            this.$emit(
                'select-client-specific-fields',
                this.selectedChangeset === parentChildTypes.parent ? this.parent : null
            );
        },

        toggleSelection(selectedChangeset) {
            this.selectChanges(parentChildTypes[`${selectedChangeset}`]);
            this.gridOptions.api.refreshCells();
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.client-specific-fields-changes-viewer {
    @include flex-column;
    margin-bottom: 2rem;

    &__actions {
        width: 100%;
        height: 2rem;
        background-color: $promo-white;
    }
}

.client-specific-fields-changes-viewer::v-deep {
    .ag-header-container,
    .ag-header,
    .ag-header-viewport {
        height: 4rem !important;
        min-height: 4rem !important;
    }

    .ag-center-cols-clipper {
        border-top: solid 0.1rem $promo-table-blue-bg-colour !important;
    }
}
</style>
